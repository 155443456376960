import { toast } from 'react-toastify';
import { URL_API } from '../utils/helpers';
import data from './data.json';
import axios from 'axios';
import { useEffect, useState } from 'react';

export default () => {

  const [products, setProducts] = useState([]);

  useEffect(() => {
    axios.get(`${URL_API}/products`)
      .then(response => {
        setProducts(response.data.products || []);
      })
      .catch(error => {
        toast.error('Une erreur s\'est produite');
        console.error(error);
      });
  }, []);

  return products;
}