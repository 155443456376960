import React, { Component } from 'react';
import axios from 'axios';
import { URL_API } from '../../utils/helpers';
import { toast } from 'react-toastify';

class ContactForm extends Component {
    state = {
        name: '',
        email: '',
        message: '',
        lastname: '',
        subject: '',
        error: {}
    }

    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = '';

        this.setState({
            [e.target.name]: e.target.value,
            error
        });
    }

    subimtHandler = (e) => {
        e.preventDefault();

        const { name, email, message, lastname, subject, error } = this.state;

        if (name === '') {
            error.name = "Please enter your name";
        }
        if (email === '') {
            error.email = "Please enter your email";
        }
        if (message === '') {
            error.message = "Please enter your message";
        }
        if (lastname === '') {
            error.lastname = "Please enter your Lastname";
        }
        if (subject === '') {
            error.subject = "Please enter a subject";
        }

        this.setState({ error });

        if (Object.keys(error).some(key => error[key] !== '')) {
            return;
        }

        const formData = {
            name, email, message, lastname, subject
        };

        axios.post(`${URL_API}/nous-contacter`, formData)
            .then((response) => {
                this.setState({
                    name: '',
                    email: '',
                    message: '',
                    lastname: '',
                    subject: '',
                    error: {}
                });

                toast.success(response.data.message);
            })
            .catch((error) => {
                console.error('Error:', error.response ? error.response.data : error.message);
                toast.warning('Oups !! Erreur d\'envoie d\'e-mail');
            });
    }

    render() {
        const { name, email, message, lastname, subject, error } = this.state;

        return (
            <form onSubmit={this.subimtHandler} className="form">
                <div className="row">

                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={lastname} onChange={this.changeHandler} type="text" name="lastname" placeholder="Nom" />
                            <p>{error.lastname ? error.lastname : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={name} onChange={this.changeHandler} type="text" name="name" placeholder="Nom de famille" />
                            <p>{error.name ? error.name : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={email} type="email" name="email" placeholder="E-mail" />
                            <p>{error.email ? error.email : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={subject} type="text" name="subject" placeholder="Subject" />
                            <p>{error.subject ? error.subject : ''}</p>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="form-field">
                            <textarea onChange={this.changeHandler} value={message} name="message" placeholder="Message"></textarea>
                            <p>{error.message ? error.message : ''}</p>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="form-submit">
                            <button type="submit" className="theme-btn">Envoyer un message</button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default ContactForm;
