import React, { useEffect, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import { URL_API } from '../../../utils/helpers';
import axios from 'axios';

const ReviewForm = ({ id_product }) => {
    const [value, setValue] = useState({
        name: '',
        email: '',
        rating: '',
        message: '',
    });

    const changeHandler = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
        validator.showMessages();
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const submitForm = async (e) => {
        e.preventDefault();
        if (validator.allValid()) {

            try {

                const response = await axios.post(`${URL_API}/reviews-store`, {
                    ...value,
                    id_product
                });

                if (response.data.status === 200) {
                    setValue({
                        name: '',
                        email: '',
                        message: '',
                        rating: ''
                    });
                    validator.hideMessages();
                    toast.success('You submitted the form and stuff!');
                }

            } catch (error) {

                toast.info('Oups !! Erreur d\'ajout du commentaire ')

            }


        } else {
            validator.showMessages();
            toast.error('Empty field is not allowed!');
        }
    };

    return (
        <Grid>
            <h4>Commenter cet article</h4>

            <Grid className="reviewForm my-4">
                <form onSubmit={(e) => submitForm(e)}>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className="formDefault"
                                fullWidth
                                value={value.name}
                                variant="outlined"
                                name="name"
                                label="Name"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('name', value.name, 'required|alpha_space')}
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                className="formDefault"
                                fullWidth
                                value={value.email}
                                variant="outlined"
                                name="email"
                                label="Email"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('email', value.email, 'required|email')}
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <TextField
                                className="formDefault"
                                fullWidth
                                value={value.rating}
                                variant="outlined"
                                name="rating"
                                label="Rating"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('rating', value.rating, 'required|numeric|min:1,num|max:5,num')}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="formDefault"
                                fullWidth
                                value={value.message}
                                variant="outlined"
                                name="message"
                                label="Message"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('message', value.message, 'required|string|min:2|max:500')}
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" className="theme-btn">Submit</Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>

    )
};

export default ReviewForm;