import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { URL_API } from "../../utils/helpers";


const Testimonial = () => {

    const [settings, setSettings] = useState({
        dots: false,
        arrows: true,
        speed: 1200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        fade: true
    })

    const [testi, setTesti] = useState([]);

    useEffect(() => {
        axios.get(`${URL_API}/getTestimoniale`)
            .then(response => {
                setTesti(response.data.testi);
            })
            .catch(error => {
                toast.error("Oups !! Erreur de récupération des données");
            });
    }, []);

    return (
        <section className="testimonial-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="section-title">
                            <h2><span>Témoignages </span>Client</h2>
                            <p>Nos clients sont au cœur de notre succès. Voici ce qu'ils disent de nous</p>
                        </div>
                    </div>
                </div>
                <div className="testimonial-wrap">
                    <div className="testimonial-active">
                        <Slider {...settings}>
                            {
                                testi.map((item, index) => (
                                    <div className="testimonial-item" key={index}>
                                        <div className="testimonial-img">
                                            <img src={item.img} alt="" />
                                            <div className="t-quote">
                                                <i className="fi flaticon-left-quote"></i>
                                            </div>
                                        </div>
                                        <div className="testimonial-content">
                                            <p dangerouslySetInnerHTML={{ __html: item.commentaire }} />
                                            <div className="testimonial-author">
                                                <h3>{item.fullname}</h3>
                                                <span>{item.poste}</span>
                                            </div>
                                            <div className="t-content-quote">
                                                <i className="fi flaticon-left-quote"></i>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonial;