import React, { Fragment, useEffect, useState } from 'react';
import { connect } from "react-redux";
import OrderRecivedSec from '../../components/OrderRecivedSec';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { URL_API } from '../../utils/helpers';
import { toast } from 'react-toastify';


const OrderRecived = ({ cartList }) => {

    const { id } = useParams();
    const token = localStorage.getItem('login');
    const [order, setOrder] = React.useState([]);
    const [orderItem, setOrderItem] = React.useState([]);

    const getOrder = async () => {

        try {

            const response = await axios.get(`${URL_API}/order/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.data.status == 500) {
                toast.error(response.data.message)
            }
            if (response.data.status == 200) {
                setOrder(response.data.order)
                setOrderItem(response.data.order_items)
            }
        } catch (error) {
            toast.info('Oups !! Une erreur c\'est produite')
        }
    }

    useEffect(() => {
        getOrder()
    }, [])

    return (
        <Fragment>
            <OrderRecivedSec cartList={orderItem} order={order} />
        </Fragment>
    )
};

const mapStateToProps = state => {
    return {
        cartList: state.cartList.cart,
        symbol: state.data.symbol
    }
};

export default connect(mapStateToProps)(OrderRecived);
