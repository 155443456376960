import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Alert, } from "reactstrap";
import classnames from "classnames";
import ProductGrid from "../ProductGrid";
import ProductList from "../ProductList";
import { Pagination } from "../../utils/helpers";

const FilterAllProduct = ({ products, addToCartProduct, addToWishListProduct }) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Nombre de produits par page

  // Calculer l'index des produits à afficher pour la page courante
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

  // Calculer le nombre total de pages
  const totalPages = Math.ceil(products.length / itemsPerPage);

  // Fonction pour changer de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="col-lg-8">
      <div className="shop-section-top-inner">
        <div className="shoping-list">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
              >
                <i className="fa fa-th "></i>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
              >
                <i className="fa fa-list"></i>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className="shoping-product">
          {
            totalPages > 1 ? <Pagination totalPages={totalPages} paginate={paginate} currentPage={currentPage} /> : null

          }
        </div>
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          {
            products.length > 0 ? (
              <>
                <ProductGrid
                  addToCartProduct={addToCartProduct}
                  // addToWishListProduct={addToWishListProduct}
                  products={currentProducts}
                />
              </>

            ) : (
              <Alert color="info" className="text-center">
                Aucun produit ne correspond à votre filtre. Essayez d'ajuster vos critères de recherche.
              </Alert>
            )
          }

        </TabPane>

        <TabPane tabId="2">
          {
            products.length > 0 ? (
              <ProductList
                addToCartProduct={addToCartProduct}
                // addToWishListProduct={addToWishListProduct}
                products={currentProducts}
              />
            ) : (
              <Alert color="info" className="text-center">
                Aucun produit ne correspond à votre filtre. Essayez d'ajuster vos critères de recherche.
              </Alert>
            )
          }

        </TabPane>
      </TabContent>
    </div>
  );
};

export default FilterAllProduct;
