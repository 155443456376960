// images
import blogImg1 from "../images/blog/img-1.jpg";
import blogImg2 from "../images/blog/img-2.jpg";
import blogImg3 from "../images/blog/img-3.jpg";

import blogAvaterImg1 from "../images/blog/blog-avater/img-1.jpg";
import blogAvaterImg2 from "../images/blog/blog-avater/img-2.jpg";
import blogAvaterImg3 from "../images/blog/blog-avater/img-3.jpg";

import blogSingleImg1 from "../images/blog/img-5.jpg";
import blogSingleImg2 from "../images/blog/img-6.jpg";
import blogSingleImg3 from "../images/blog/img-7.jpg";



const blogs = [
    {
        id: '1',
        title: 'Solutions pour les Entreprises',
        screens: blogImg1,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Jenefer Willy',
        authorTitle: 'Farmer',
        authorImg: blogAvaterImg1,
        create_at: '14 AUG,21',
        blogSingleImg: blogSingleImg1,
        comment: '35',
        blClass: 'format-standard-image',
    },
    {
        id: '2',
        title: 'How to get more traffic in your website of ecommerce.',
        screens: blogImg2,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Konal Biry',
        authorTitle: 'Farmer',
        authorImg: blogAvaterImg2,
        create_at: '16 AUG,21',
        blogSingleImg: blogSingleImg2,
        comment: '80',
        blClass: 'format-standard-image',
    },
    {
        id: '3',
        title: '25 Rules and regulation to be successful in your business.',
        screens: blogImg3,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Jenefer Willy',
        authorTitle: 'Farmer',
        authorImg: blogAvaterImg3,
        create_at: '18 AUG,21',
        blogSingleImg: blogSingleImg3,
        comment: '95',
        blClass: 'format-video',
    },
];
export default blogs;