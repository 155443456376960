import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link, useNavigate } from "react-router-dom";

import './style.scss';
import axios from 'axios';
import usePreviousPage, { URL_API } from '../../utils/helpers';



const LoginPage = (props) => {

    const navigate = useNavigate();

    const [value, setValue] = useState({
        email: '',
        password: '',
        remember: false,
    });

    const changeHandler = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
        validator.showMessages();
    };

    const rememberHandler = () => {
        setValue({ ...value, remember: !value.remember });
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'text-danger'
    }));



    const submitForm = (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            const email = value.email;
            const password = value.password

            if (email !== '' && password !== '') {

                // WARNING: For POST requests, body is set to null by browsers.
                // var data = JSON.stringify({
                //     "email": "admin@gmail.com",
                //     "password": "bca@1234"
                // });

                // var xhr = new XMLHttpRequest();
                // xhr.withCredentials = true;

                // xhr.addEventListener("readystatechange", function () {
                //     if (this.readyState === 4) {
                //         console.log(this.responseText);
                //     }
                // });

                // xhr.open("POST", "https://api.bestcentrale.com/api/login");
                // xhr.setRequestHeader("sec-ch-ua-platform", "\"Linux\"");
                // xhr.setRequestHeader("Referer", "http://localhost:3036/");
                // xhr.setRequestHeader("User-Agent", "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/129.0.0.0 Safari/537.36");
                // xhr.setRequestHeader("Accept", "application/json, text/plain, */*");
                // xhr.setRequestHeader("sec-ch-ua", "\"Google Chrome\";v=\"129\", \"Not=A?Brand\";v=\"8\", \"Chromium\";v=\"129\"");
                // xhr.setRequestHeader("Content-Type", "application/json");
                // xhr.setRequestHeader("sec-ch-ua-mobile", "?0");
                // // WARNING: Cookies will be stripped away by the browser before sending the request.
                // xhr.setRequestHeader("Cookie", "o2s-chl=71a9f3dc534645ea52fa55f93aefaa6f");

                // xhr.send(data);

                // const axios = require('axios');
                // let data = JSON.stringify({
                //     "email": "admin@gmail.com",
                //     "password": "bca@1234"
                // });

                // let config = {
                //     method: 'post',
                //     maxBodyLength: Infinity,
                //     url: 'https://api.bestcentrale.com/api/login',
                //     headers: {
                //         'sec-ch-ua-platform': '"Linux"',
                //         'Referer': 'http://localhost:3036/',
                //         'User-Agent': 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/129.0.0.0 Safari/537.36',
                //         'Accept': 'application/json, text/plain, */*',
                //         'sec-ch-ua': '"Google Chrome";v="129", "Not=A?Brand";v="8", "Chromium";v="129"',
                //         'Content-Type': 'application/json',
                //         'sec-ch-ua-mobile': '?0',
                //         'Cookie': 'o2s-chl=71a9f3dc534645ea52fa55f93aefaa6f'
                //     },
                //     data: data
                // };

                // axios.request(config)
                //     .then((response) => {
                //         console.log(JSON.stringify(response.data));
                //     })
                //     .catch((error) => {
                //         console.log(error);
                //     });

                axios.post(`${URL_API}/login`, { email, password })
                    .then(response => {
                        if (response.data.status === 401) {
                            toast.error(response.data.errors)
                        }
                        if (response.data.status === 200) {
                            localStorage.setItem("login", response.data.access_token);
                            localStorage.setItem('user', JSON.stringify(response.data.user));
                            setValue({
                                email: '',
                                password: '',
                                remember: false
                            });
                            navigate(`${process.env.PUBLIC_URL}${localStorage.previousPage}`);
                        }
                    })
                    .catch(error => {
                        toast.error("Please Enter valid email or password...!");
                    });
                // toast.success('You successfully Login on best centrale d\'achat !');
                // navigate('/home');
            } else {
                validator.showMessages();
                toast.error('Veuillez saisir vos informations');
            }
        } else {
            validator.showMessages();
            toast.error('Veuillez saisir vos informations');
        }
    };
    return (
        <Grid className="loginWrapper">
            <Grid className="loginForm">
                <h2>Sign In</h2>
                <p>Sign in to your account</p>
                <form onSubmit={submitForm}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="E-mail"
                                value={value.email}
                                variant="outlined"
                                name="email"
                                label="E-mail"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('email', value.email, 'required|email')}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Password"
                                value={value.password}
                                variant="outlined"
                                name="password"
                                type="password"
                                label="Password"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('password', value.password, 'required')}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className="formAction">
                                <FormControlLabel
                                    control={<Checkbox checked={value.remember} onChange={rememberHandler} />}
                                    label="Remember Me"
                                />
                                <Link to="/forgot-password">Forgot Password?</Link>
                            </Grid>
                            <Grid className="formFooter">
                                <Button fullWidth className="cBtnTheme" type="submit">Login</Button>
                            </Grid>
                            <Grid className="loginWithSocial">
                                <Button className="facebook"><i className="fa fa-facebook"></i></Button>
                                <Button className="twitter"><i className="fa fa-twitter"></i></Button>
                                <Button className="linkedin"><i className="fa fa-linkedin"></i></Button>
                            </Grid>
                            <p className="noteHelp">Don't have an account? <Link to="/register">Create free account</Link>
                            </p>
                            <p className="noteHelp">Revenir à la <Link to="/">Boutique</Link>
                            </p>
                        </Grid>
                    </Grid>
                </form>
                <div className="shape-img">
                    <i className="fi flaticon-honeycomb"></i>
                </div>
            </Grid>
        </Grid>
    )
};

export default LoginPage;