import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar'
import About from '../../components/about'
import Category2 from '../../components/Category2'
import TeamSection from '../../components/team'
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import usePreviousPage from '../../utils/helpers';


const AboutPage = () => {
    usePreviousPage();
    return (
        <Fragment>
            <Navbar hClass={"header-style-2"} />
            <PageTitle pageTitle={'A Propos de nous'} pagesub={'A Propos de nous'} />
            <About />
            <Category2 StyleClass={'style-2'} />
            <TeamSection />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default AboutPage;
