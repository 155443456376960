import React, { useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import ProductTabMenu from "../ProductTabMenu";
import { truncateDescription } from "../../utils/helpers";

const ProductSingleSec = ({ item, addToCart }) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const [qty, setQty] = useState(1);
  const colorArray = item.colors.split(',');
  const sizeArray = item.sizes.split(',');
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedSize, setSelectedSize] = useState('');

  return (
    <div className="product-single-section section-padding">
      <div className="container">
        <div className="product-details">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="product-single-img">
                <div className="product-active">
                  <div className="item">
                    <Zoom>
                      <img
                        alt="that wanaka tree"
                        src={item.proImg ? item.proImg : ""}
                        width="500"
                      />
                    </Zoom>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="product-single-content">
                <h5>{item.title}</h5>
                <h6>{ }</h6>
                <ul className="rating">
                  <li>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </li>
                  <li>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </li>
                  <li>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </li>
                  <li>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </li>
                  <li>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </li>
                </ul>
                <p>
                  {
                    truncateDescription(item.description, 500)
                  }
                </p>
                <div className="product-filter-item color">
                  <div className="color-name">
                    <span>Color :</span>
                    <ul>
                      {colorArray.map((color, index) => (
                        <li key={index} className="color">
                          <input
                            id={`color-${index}`}
                            type="radio"
                            name="color"
                            value={color}
                            onChange={() => setSelectedColor(color)}
                          />
                          <label htmlFor={`color-${index}`} style={{ backgroundColor: color }}>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="product-filter-item color filter-size">
                  <div className="color-name">
                    <span>Weight :</span>
                    <ul>
                      {sizeArray.map((size, index) => (
                        <li key={index} className="color">
                          <input
                            id={`size-${index}`}
                            type="radio"
                            name="size"
                            value={size}
                            onChange={() => setSelectedSize(size)}
                          />
                          <label htmlFor={`size-${index}`}>{size}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="pro-single-btn">
                  <Grid className="quantity cart-plus-minus">
                    <Button
                      className="dec qtybutton"
                      onClick={() => setQty(qty <= 1 ? 1 : qty - 1)}
                    >
                      -
                    </Button>
                    <input
                      value={qty}
                      onChange={() => setQty(qty)}
                      type="text"
                    />
                    <Button
                      className="inc qtybutton"
                      onClick={() => setQty(qty + 1)}
                    >
                      +
                    </Button>
                  </Grid>
                  <button
                    className="theme-btn"
                    onClick={() => addToCart(item, qty)}
                  >
                    Add to cart
                  </button>
                </div>
                {/* <div className="social-share">
                  <span>Share with : </span>
                  <ul className="socialLinks">
                    <li>
                      <Link onClick={ClickHandler} to="/product-single">
                        <i className="fa fa-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/product-single">
                        <i className="fa fa-linkedin"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/product-single">
                        <i className="fa fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/product-single">
                        <i className="fa fa-instagram"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/product-single">
                        <i className="fa fa-youtube-play"></i>
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <ProductTabMenu item={item.description} id={item.id} />
      </div>
    </div>
  );
};

export default ProductSingleSec;
