import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Contactpage from '../../components/Contactpage'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import usePreviousPage from '../../utils/helpers';


const ContactPage = () => {
    usePreviousPage();
    return (
        <Fragment>
            <Navbar hClass={"header-style-2"} />
            <PageTitle pageTitle={'Contactez-nous'} pagesub={'Contact'} />
            <Contactpage />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default ContactPage;

