import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from '../../images/slider/slide-2.jpg'
import hero2 from '../../images/slider/slide-3.jpg'
import hero3 from '../../images/slider/slide-4.jpg'
import axios from "axios";
import { URL_API } from "../../utils/helpers";
import { toast } from "react-toastify";

const HeroP2 = () => {
    const [settings, setSettings] = useState({
        dots: true,
        arrows: true,
        speed: 1200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        fade: true
    })

    const [sliders, setSliders] = useState([]);

    const getData = () => {
        axios.get(`${URL_API}/getSlider`)
            .then(response => {
                setSliders(response.data.slidersData);
            })
            .catch(error => {
                toast.error("Oups !! Erreur de récupération des données");
            });
    }

    useEffect(() => {
        getData();
    }, [sliders]);

    return (
        <section className="hero hero-style-2">
            <div className="hero-slider">
                <Slider {...settings}>
                    {
                        sliders.length > 0 ? sliders.map((item, index) => (
                            <div className="slide" key={index}>
                                <div className="slide-inner" style={{ backgroundImage: `url(${encodeURI(item.images)})` }}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col col-lg-5 slide-caption">
                                                <div className="slide-title">
                                                    <h2> {item.title} </h2>
                                                </div>
                                                <div className="btns">
                                                    <Link to="/shop" className="btn theme-btn">Acheter maintenant <i className="fa fa-angle-double-right"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : (
                            <>
                                <div className="slide">
                                    <div className="slide-inner" style={{ backgroundImage: `url(${hero1})` }}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col col-lg-5 slide-caption">
                                                    <div className="slide-title">
                                                        <h2><span>Best</span> Centrale <span>d'Achat </span></h2>
                                                    </div>
                                                    <div className="btns">
                                                        <Link to="/shop" className="btn theme-btn">Acheter maintenant <i className="fa fa-angle-double-right"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-inner" style={{ backgroundImage: `url(${hero2})` }}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col col-lg-5 slide-caption">
                                                    <div className="slide-title">
                                                        <h2><span>Best</span> Centrale <span>d'Achat </span></h2>
                                                    </div>
                                                    <div className="btns">
                                                        <Link to="/shop" className="btn theme-btn">Acheter maintenant <i className="fa fa-angle-double-right"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="slide">
                                    <div className="slide-inner" style={{ backgroundImage: `url(${hero3})` }}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col col-lg-5 slide-caption">
                                                    <div className="slide-title">
                                                        <h2><span>Best</span> Centrale <span>d'Achat </span></h2>
                                                    </div>
                                                    <div className="btns">
                                                        <Link to="/shop" className="btn theme-btn">Acheter maintenant <i className="fa fa-angle-double-right"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }

                </Slider>
            </div>
        </section>
    )
}

export default HeroP2

// class Hero2 extends Component {
//     render() {

//         var settings = {
// dots: true,
// arrows: true,
// speed: 1200,
// slidesToShow: 1,
// slidesToScroll: 1,
// autoplay: true,
// autoplaySpeed: 2500,
// fade: true
//         };

//         return (
//     <section className="hero hero-style-2">
//         <div className="hero-slider">
//             <Slider {...settings}>
//                 <div className="slide">
//                     <div className="slide-inner" style={{ backgroundImage: `url(${hero1})` }}>
//                         <div className="container">
//                             <div className="row">
//                                 <div className="col col-lg-5 slide-caption">
//                                     <div className="slide-title">
//                                         <h2><span>Best</span> Centrale <span>d'Achat </span></h2>
//                                     </div>
//                                     <div className="btns">
//                                         <Link to="/shop" className="btn theme-btn">Acheter maintenant <i className="fa fa-angle-double-right"></i></Link>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="slide">
//                     <div className="slide-inner" style={{ backgroundImage: `url(${hero2})` }}>
//                         <div className="container">
//                             <div className="row">
//                                 <div className="col col-lg-5 slide-caption">
//                                     <div className="slide-title">
//                                         <h2><span>Best</span> Centrale <span>d'Achat </span></h2>
//                                     </div>
//                                     <div className="btns">
//                                         <Link to="/shop" className="btn theme-btn">Acheter maintenant <i className="fa fa-angle-double-right"></i></Link>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="slide">
//                     <div className="slide-inner" style={{ backgroundImage: `url(${hero3})` }}>
//                         <div className="container">
//                             <div className="row">
//                                 <div className="col col-lg-5 slide-caption">
//                                     <div className="slide-title">
//                                         <h2><span>Best</span> Centrale <span>d'Achat </span></h2>
//                                     </div>
//                                     <div className="btns">
//                                         <Link to="/shop" className="btn theme-btn">Acheter maintenant <i className="fa fa-angle-double-right"></i></Link>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </Slider>
//         </div>
//     </section>
// )
//     }
// }

// export default Hero2;