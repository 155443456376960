import React from 'react'
import { Link } from 'react-router-dom'
import catimg from '../../images/category/icon-1.png'
import catimg2 from '../../images/category/icon-2.png'
import catimg3 from '../../images/category/icon-3.png'


const Category2 = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <section className={`category-area-s2 section-padding ${props.StyleClass}`}>
            <div className="container">
                <div className="category-wrap">
                    <div className="section-title">
                        <h2>
                            Reconnaissances <span>et</span> certifications
                        </h2>
                        <p className='w-50 mx-auto'>
                            La réputation de Best Centrale d'Achat SARL repose sur notre engagement envers l'excellence et la conformité aux normes les plus strictes. Nous sommes fiers d’être sur les démarches de certifications suivantes.
                        </p>
                    </div>
                    <p className='text-center'></p>
                    <div className="row">
                        <div className="col-xl-3 col-lg-6 col-12">
                            <div className="category-item">
                                {/* <div className="category-icon">
                                    <img src={catimg} alt="" />
                                </div> */}
                                <div className="category-content">
                                    <h2><Link onClick={ClickHandler} to="#">Certification ISO 9001</Link></h2>
                                    <p>Reconnaissance de notre système de gestion de la qualité (en cours).</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-12">
                            <div className="category-item">
                                {/* <div className="category-icon">
                                    <img src={catimg2} alt="" />
                                </div> */}
                                <div className="category-content">
                                    <h2><Link onClick={ClickHandler} to="#">Certification ISO 14001</Link></h2>
                                    <p>Témoignage de notre engagement envers la gestion environnementale (en cours).</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-12">
                            <div className="category-item">
                                {/* <div className="category-icon">
                                    <img src={catimg3} alt="" />
                                </div> */}
                                <div className="category-content">
                                    <h2><Link onClick={ClickHandler} to="#">Prix de l'Innovation</Link></h2>
                                    <p>Récompense pour nos solutions innovantes dans le secteur des fournitures d'entreprise (en cours).</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-12">
                            <div className="category-item">
                                {/* <div className="category-icon">
                                    <img src={catimg3} alt="" />
                                </div> */}
                                <div className="category-content">
                                    <h2><Link onClick={ClickHandler} to="#">Label "Entreprise Responsable"</Link></h2>
                                    <p>Distinction pour notre approche éthique et durable des affaires.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Category2;