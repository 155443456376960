import React, { Fragment, useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import usePreviousPage, { formatDate, Pagination, URL_API } from '../../utils/helpers';
import { Link } from 'react-router-dom';
import VideoModal from '../../components/ModalVideo';
import { toast } from 'react-toastify';
import axios from 'axios';

const NosSolutions = () => {
    usePreviousPage();

    const [menus, setMenus] = useState([]);
    const [menusItems, setMenusItems] = useState([]);
    const [currentItem, setCurrentItem] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentMenusItems = menusItems.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(menusItems.length / itemsPerPage);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const getItems = (id = null) => {
        if (id === null) {
            const firstItem = menusItems.length > 0 ? menusItems[0] : null;
            setCurrentItem(firstItem);
        } else {
            const selectedItem = menusItems.find((item) => item.id === id);
            setCurrentItem(selectedItem);
        }

    }

    useEffect(() => {
        axios.get(`${URL_API}/getNosSolutions`)
            .then(response => {
                setMenus(response.data.menus || []);
                setMenusItems(response.data.menusItems || []);
            })
            .catch(error => {
                toast.error("Oups !! Erreur de récupération des données");
            });
    }, []);

    useEffect(() => {
        if (menusItems.length > 0) {
            getItems();
        }
    }, [menusItems]);

    return (
        <Fragment>
            <Navbar hClass={"header-style-2"} />
            <PageTitle pageTitle={'Nos Solutions'} pagesub={'nos solutions'} />

            <section className="wpo-blog-pg-section section-padding pt-5">
                <div className="container">
                    <div className='pb-5' style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: menus.description || '' }}>
                    </div>
                    <div className="row">
                        <div className={`col col-lg-8 col-12 order-lg-2`}>
                            <div className="wpo-blog-content">
                                {currentItem && (  // Vérifier si currentItem n'est pas null avant de rendre
                                    <div className={`post format-video`}>
                                        <div className="entry-media video-holder">
                                            <img src={currentItem.img} alt={currentItem.title} />
                                            {/* <VideoModal /> */}
                                        </div>
                                        <div className="entry-details mt-4">
                                            <h3>
                                                <Link onClick={ClickHandler} to={"#"}>{currentItem.title}</Link>
                                            </h3>
                                            <p dangerouslySetInnerHTML={{ __html: currentItem.description }} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={`col col-lg-4 col-12 order-lg-2`}>
                            <div className="blog-sidebar">
                                <div className="widget recent-post-widget">
                                    <h3>Nos Solutions</h3>
                                    <div className="posts">
                                        {currentMenusItems.map((item, Bitem) => (
                                            <div className="post" key={Bitem}>
                                                <div className="img-holder">
                                                    <img src={item.img} alt={item.title} />
                                                </div>
                                                <div className="details">
                                                    {/* <h4><Link onClick={getItems(item.id)} to={`#`}>{item.title}</Link></h4> */}
                                                    <h4><Link onClick={() => getItems(item.id)} to={`#`}>{item.title}</Link></h4>
                                                    <span className="date">{formatDate(item.created_at)}</span>
                                                </div>
                                            </div>
                                        ))}
                                        {totalPages > 1 ? <Pagination totalPages={totalPages} paginate={paginate} currentPage={currentPage} /> : null}
                                    </div>
                                </div>
                                {/* <div className="wpo-contact-widget widget">
                                    <h2>Comment pouvons-nous<br /> vous aider !</h2>
                                    <p>Chez Best Centrale d'Achat SARL, nous valorisons la communication ouverte et l'accessibilité.</p>
                                    <Link onClick={ClickHandler} to="/contact">Nous Contacter</Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default NosSolutions;
