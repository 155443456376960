import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Link, useNavigate } from "react-router-dom";
import './style.scss';
import axios from 'axios';
import { URL_API } from '../../utils/helpers';

const SignUpPage = () => {

    const push = useNavigate();
    const [value, setValue] = useState({
        email: '',
        first_name: '',
        last_name: '',
        telephone: '',
        adresse: '',
        password: '',
        confirm_password: '',
    });

    const [error, setError] = useState({
        email: '',
        first_name: '',
        last_name: '',
        telephone: '',
        adresse: '',
        password: '',
        confirm_password: '',
    })

    const changeHandler = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
        validator.showMessages();
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'text-danger'
    }));

    const submitForm = async (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            try {
                const response = await axios.post(`${URL_API}/store-users`, {
                    ...value
                });

                if (response.data.status === 400) {
                    setError(response.data.errors)
                    toast.info(response.data.message)
                }

                if (response.data.status === 200) {
                    toast.success(response.data.message);
                    push('/login');
                    setValue({
                        email: '',
                        first_name: '',
                        last_name: '',
                        telephone: '',
                        adresse: '',
                        password: '',
                        confirm_password: '',
                    });
                }

                if (response.data.stats === 401) {
                    toast.warning(response.data.message)
                }


            } catch (error) {
                // console.error('Erreur lors de la requête:', error.response || error.message);
                toast.error('Une erreur est survenue lors de l\'enregistrement.');
            }
        } else {
            validator.showMessages();
            toast.error('Tous les champs sont obligatoires!');
        }
    };

    return (
        <Grid className="loginWrapper">
            <Grid className="loginForm">
                <h2>Signup</h2>
                <p>Signup your account</p>
                <form onSubmit={submitForm}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="First Name"
                                value={value.first_name}
                                variant="outlined"
                                name="first_name"
                                label="Nom"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={changeHandler}
                                onChange={changeHandler}
                            />
                            {validator.message('first name', value.first_name, 'required|alpha')}
                            {error.first_name && <span className="text-danger">{error.first_name}</span>}
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Last Name"
                                value={value.last_name}
                                variant="outlined"
                                name="last_name"
                                label="Prénom"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={changeHandler}
                                onChange={changeHandler}
                            />
                            {validator.message('last name', value.last_name, 'required|alpha')}
                            {error.last_name && <span className="text-danger">{error.last_name}</span>}

                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="E-mail"
                                value={value.email}
                                variant="outlined"
                                name="email"
                                label="E-mail"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={changeHandler}
                                onChange={changeHandler}
                            />
                            {validator.message('email', value.email, 'required|email')}
                            {error.email && <span className="text-danger">{error.email}</span>}
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Téléphone"
                                value={value.telephone}
                                variant="outlined"
                                name="telephone"
                                label="Téléphone"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={changeHandler}
                                onChange={changeHandler}
                            />
                            {validator.message('telephone', value.telephone, 'required|numeric')}
                            {error.telephone && <span className="text-danger">{error.telephone}</span>}
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Adresse"
                                value={value.adresse}
                                variant="outlined"
                                name="adresse"
                                label="Adresse"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={changeHandler}
                                onChange={changeHandler}
                            />
                            {validator.message('adresse', value.adresse, 'required|alpha_num')}
                            {error.adresse && <span className="text-danger">{error.adresse}</span>}
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Password"
                                value={value.password}
                                variant="outlined"
                                type="password"
                                name="password"
                                label="Password"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('password', value.password, 'required')}
                            {error.password && <span className="text-danger">{error.password}</span>}
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Confirm Password"
                                value={value.password}
                                variant="outlined"
                                name="confirm_password"
                                type="password"
                                label="Confirm Password"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('confirm password', value.confirm_password, `in:${value.password}`)}
                        </Grid>

                        <Grid item xs={12}>
                            <Grid className="formFooter">
                                <Button fullWidth className="cBtn cBtnLarge cBtnTheme" type="submit">Sign Up</Button>
                            </Grid>
                            <Grid className="loginWithSocial">
                                <Button className="facebook"><i className="fa fa-facebook"></i></Button>
                                <Button className="twitter"><i className="fa fa-twitter"></i></Button>
                                <Button className="linkedin"><i className="fa fa-linkedin"></i></Button>
                            </Grid>
                            <p className="noteHelp">Already have an account? <Link to="/login">Return to Sign In</Link></p>
                            <p className="noteHelp">Revenir à la <Link to="/">Boutique</Link></p>
                        </Grid>
                    </Grid>
                </form>
                <div className="shape-img">
                    <i className="fi flaticon-honeycomb"></i>
                </div>
            </Grid>
        </Grid>
    );
};

export default SignUpPage;
