import React from 'react';
import { Link } from 'react-router-dom'
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { totalPrice } from "../../utils";
import './style.scss'
import { formatPrice, getDiscountedPrice } from '../../utils/helpers';
import { Col, Row } from 'reactstrap';
import Logo from '../../images/modal3.jpg'

const OrderRecivedSec = ({ cartList, order }) => {

    return (
        <section className="cart-recived-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="order-top">
                        <h2>Merci pour votre commande ! <span>votre commande a été reçue</span></h2>
                        <Link to='/' className="theme-btn">Retour à la boutique</Link>
                    </div>
                    <Grid className="cartStatus">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid className="cartTotals">
                                    <Table>
                                        <TableBody>

                                            <TableRow className="totalProduct">
                                                <TableCell><h4 className='mt-3'> Informations Commande</h4></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="right"> <h4 className='mt-3'>Informations Client</h4> </TableCell>
                                            </TableRow>
                                            <TableRow className="totalProduct">
                                                <TableCell>
                                                    <span className='mt-3 fw-bold fs-3'>Commande No: BCA-000{order.id}</span> <br></br>
                                                    <span className='mt-3 fs-5'>Adresse: Conakry-Gbessia-Kondeboungny </span> <br></br>
                                                    <span className='mt-3 fs-5'>Email: contact@bestcentrale.com</span> <br></br>
                                                    <span className='mt-3 fs-5'>Tel: +224 621 02 39 66</span> <br></br>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <img src={Logo} className="App-logo" alt="logo" />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <span className='mt-3 fw-bold fs-5'>{order.fname2 ?? order.user_firstname} {order.lname2 ?? order.user_lastname} </span> <br></br>
                                                    <span className='mt-3 fs-5'> {order.addresse2 ?? order.user_adresse} </span> <br></br>
                                                    <span className='mt-3 fs-5'> {order.email2 ?? order.user_email} </span> <br></br>
                                                    <span className='mt-3 fs-5'>{order.phone2 ?? order.user_phone}</span> <br></br>
                                                </TableCell>
                                            </TableRow>
                                            <h4 className='mt-3'>Order details</h4>
                                            {cartList.map(item => (
                                                <TableRow key={item.id}>
                                                    <TableCell><img src={item.product_img} alt="" /> <span className='fs-5'>{item.product_name} {formatPrice(item.priceSale)} x {item.quantity}</span> </TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell
                                                        align="right" className='fs-5'>{formatPrice(item.quantity * item.priceSale)}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow className="totalProduct">
                                                <TableCell className='fs-5'>Total product</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="right" className='fs-5'>{cartList.length}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className='fs-5'>Sous-Total</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="right" className='fs-5'>{formatPrice(order.total_amount)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className='fs-5'>Frais de Livraison</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="right" className='fs-5'>{formatPrice(order.frais_livraison)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className='fs-3'><b>Total Price</b></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell
                                                    align="right" className='fs-3'><b>{formatPrice(order.frais_livraison + order.total_amount)}</b></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className="order-top mt-5">
                        <h2>Merci pour votre commande ! <span>votre commande a été reçue</span></h2>
                        <Link to='/' className="theme-btn">Télécharger</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OrderRecivedSec;