import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";

import Homepage from '../HomePage'
import Homepage2 from '../HomePage2'
import AboutPage from '../AboutPage'
import ShopPage from '../ShopPage'
import ProductDetailsPage from '../ProductDetailsPage'
import CheckoutPage from '../CheckoutPage'
import OrderRecived from '../OrderRecived'
import CartPage from '../CartPage'
import WishlistPage from '../WishlistPage'
import ProjectSinglePage from '../ProjectSinglePage'
import ProjectPage from '../ProjectPage'
import BlogPage from '../BlogPage'
import BlogPageLeft from '../BlogPageLeft'
import BlogPageFullwidth from '../BlogPageFullwidth'
import BlogDetails from '../BlogDetails'
import BlogDetailsLeftSiide from '../BlogDetailsLeftSiide'
import BlogDetailsFull from '../BlogDetailsFull'
import ErrorPage from '../ErrorPage'
import ContactPage from '../ContactPage'
import LoginPage from '../LoginPage'
import SignUpPage from '../SignUpPage'
import ForgotPassword from '../ForgotPassword'
import ProfileCard from '../../components/Profile';
import MyOrder from '../../components/Profile/MyOrder';
import NosSolutions from '../NosSolutions';
import Services from '../services';
import Partenaires from '../Partenaires';
import Engagements from '../Engagement';
import Actualites from '../Actualites';
import FAQ from '../FAQ';
import Ressources from '../Ressources';


const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<ShopPage />} />
          {/* <Route path='/home' element={<Homepage />} /> */}
          <Route path='/home' element={<Homepage2 />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/shop' element={<ShopPage />} />
          <Route path='/product-single/:id' element={<ProductDetailsPage />} />
          <Route path='/checkout' element={<CheckoutPage />} />
          <Route path='/order_received/:id' element={<OrderRecived />} />
          <Route path='/cart' element={<CartPage />} />
          <Route path='/wishlist' element={<WishlistPage />} />
          <Route path='/project-single' element={<ProjectSinglePage />} />
          <Route path='/project' element={<ProjectPage />} />
          <Route path='/404' element={<ErrorPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/my-order/:id' element={<MyOrder />} />

          <Route path='/nos-solutions' element={<NosSolutions />} />
          <Route path='/services' element={<Services />} />
          <Route path='/partenaires' element={<Partenaires />} />
          <Route path='/engagements' element={<Engagements />} />
          <Route path='/actualites' element={<Actualites />} />
          <Route path='/faq' element={<FAQ />} />
          <Route path='/ressources' element={<Ressources />} />
          {/* <Route path='/blog' element={<BlogPage />} />
          <Route path='/blog-left-sidebar' element={<BlogPageLeft />} />
          <Route path='/blog-fullwidth' element={<BlogPageFullwidth />} />
          <Route path='/blog-single/:id' element={<BlogDetails />} />
          <Route path='/blog-single-left-sidebar/:id' element={<BlogDetailsLeftSiide />} />
          <Route path='/blog-single-fullwidth/:id' element={<BlogDetailsFull />} /> */}

          <Route path='/login' element={<LoginPage />} />
          <Route path='/register' element={<SignUpPage />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/my-account' element={<ProfileCard />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AllRoute;
