import React, { Fragment, useEffect, useState } from 'react';
import Navbar from '../Navbar'
import PageTitle from '../pagetitle'
import Footer from '../footer'
import Scrollbar from '../scrollbar'
import { Link, useNavigate } from 'react-router-dom'
import catimg from '../../images/category/icon-1.png'
import { formatPhoneNumber, formatPrice, logout, URL_API } from '../../utils/helpers';
import SimpleReactValidator from "simple-react-validator";
import { Divider } from '@material-ui/core';
import axios from 'axios';
import { Alert } from 'reactstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import about from '../../images/blog/about-widget.jpg'



const ProfileCard = () => {

    const [order, setOrder] = useState();
    const [user, setUser] = useState({
        adresse: '',
        created_at: '',
        email: '',
        email_verified_at: '',
        first_name: '',
        id: '',
        last_name: '',
        phone: '',
        updated_at: '',
        username: '',
    });

    const [password, setPassword] = useState({
        actuel: '',
        password: '',
        password_confirmation: '',
    });

    const [erreurPassword, setErreurPassword] = useState({
        actuel: '',
        password: '',
        password_confirmation: '',
        email: '',
        id: ''
    });

    const [infos, setInfos] = useState({
        last_name: "",
        first_name: "",
        adresse: "",
        email: "",
        telephone: "",
        id: ""
    });

    const [erreurInfos, setErreurInfos] = useState({
        last_name: "",
        first_name: "",
        adresse: "",
        email: "",
        telephone: "",
    });

    const token = localStorage.getItem('login');
    const [changePassword, setChangePassword] = useState(false);
    const [changeInfos, setChangeInfo] = useState(false);
    const [verifiedOrder, setVerifiedOrder] = useState(false);

    const changeHandlerPassword = (e) => {
        setPassword({ ...password, [e.target.name]: e.target.value });
    };

    const changeHandlerInfos = (e) => {
        setInfos({ ...infos, [e.target.name]: e.target.value });
    }

    const submitChangePassword = async (e) => {
        e.preventDefault();
        try {

            const response = await axios.post(`${URL_API}/change-password`, { ...password }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.data.status === 200) {
                toast.success(response.data.message)
                setPassword({
                    password: "",
                    password_confirmation: "",
                    actuel: ""
                });
                setErreurPassword({
                    password: "",
                    password_confirmation: "",
                    actuel: ""
                });
                logout();
            }

            if (response.data.status === 400) {
                setErreurPassword(response.data.errors);
                toast.warning(response.data.message)
            }

            if (response.data.status === 401) {
                toast.info(response.data.message)
            }



        } catch (error) {
            toast.info('Une Erreur a été généré ?')
        }

    }

    const submitChangeInfo = async (e) => {
        e.preventDefault();
        try {

            const response = await axios.post(`${URL_API}/update-infos`, { ...infos }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.data.status === 200) {
                toast.success(response.data.message)

                localStorage.setItem('user', JSON.stringify(response.data.user));
                setUser(response.data.user);
                setChangeInfo(false);
                setInfos({
                    first_name: "",
                    last_name: "",
                    adresse: "",
                    email: "",
                    telephone: ""
                })
                setErreurInfos({
                    first_name: "",
                    last_name: "",
                    adresse: "",
                    email: "",
                    telephone: ""
                });
            }

            if (response.data.status === 400) {
                setErreurInfos(response.data.errors);
                toast.warning(response.data.message)
            }

            if (response.data.status === 401) {
                toast.info(response.data.message)
            }

        } catch (error) {
            toast.info('Une Erreur a été généré ?')
        }
    }

    const toggleFormsPassword = () => {
        // logout(true);
        // navigate('/forgot-password');

        if (changePassword) {
            setChangePassword(false);

            setPassword({
                password: "",
                password_confirmation: "",
                actuel: ""
            });

            setErreurPassword({
                password: "",
                password_confirmation: "",
                actuel: ""
            })

        } else {
            setChangePassword(true);
        }
    }

    const toggleFormsInfos = () => {
        if (changeInfos) {
            setChangeInfo(false);
            setInfos({
                first_name: "",
                last_name: "",
                adresse: "",
                email: "",
                telephone: ""
            })
        } else {
            const user = JSON.parse(localStorage.getItem('user'));

            setChangeInfo(true)
            setInfos({
                first_name: user.first_name || '',
                last_name: user.last_name || '',
                adresse: user.adresse || '',
                email: user.email || '',
                telephone: user.phone || '',
                id: user.id || ""
            });
        }
    }

    const confirmation = (id) => {

        Swal.fire({
            title: 'Êtes-vous sûr ?',
            text: "De vouloir annuler cette commande ??",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, annuler !',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.isConfirmed) {
                cancelOrder(id)
            }
        });
    }

    const cancelOrder = async (id) => {

        const response = await axios.get(`${URL_API}/cancel-order/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.data.status === 400) {
            toast.warning(response.data.message)
        }

        if (response.data.status === 200) {
            toast.info(response.data.message)
            loadUserData();
        }
    }

    const loadUserData = async () => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            try {
                const parsedUser = JSON.parse(storedUser);
                const response = await axios.get(`${URL_API}/get-all-order-for-user/${parsedUser.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (response.data.status === 200) {
                    setOrder(response.data.orders);
                } else if (response.data.status === 400) {
                    setVerifiedOrder(true);
                }
                setUser(parsedUser);

            } catch (error) {
                console.error('Erreur lors de l\'analyse de JSON:', error);
                setUser(null);
            }
        } else {
            setUser(null);
        }
    };

    const chargerPassword = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        setPassword({
            email: user.email,
            id: user.id
        })
    }

    useEffect(() => {
        loadUserData();
        chargerPassword();
    }, []);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <Fragment>
            <Navbar hClass={"header-style-2"} />
            <PageTitle pageTitle={'My Account'} pagesub={'Profile'} />
            <section className={`category-area-s2 section-padding style-2`}>
                <div className="container">
                    <div className="category-wrap">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-12">

                                <div className="category-item">
                                    <h2>Mon Profile</h2>

                                    <div className="img-holder">
                                        <img src={about} alt="" />
                                    </div>
                                    <div className="category-content">
                                        <h2>{user.first_name + ' ' + user.last_name}</h2>
                                        <p>{user.adresse}</p>
                                    </div>
                                    <div>
                                        {
                                            changePassword ? (

                                                <div className='infos-pg-infos-section'>
                                                    <div className="infos-form-area">
                                                        <div className="infos-form">
                                                            <form onSubmit={submitChangePassword} className="form">
                                                                <div className="row">
                                                                    <div className="col-lg-12 col-md-12 col-12">
                                                                        <div className="form-field">
                                                                            <input value={password.actuel} onChange={changeHandlerPassword} type="password" name="actuel" placeholder="Actuel mot de passe" />
                                                                            {erreurPassword.actuel && <span className="text-danger">{erreurPassword.actuel}</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12 col-md-12 col-12">
                                                                        <div className="form-field">
                                                                            <input value={password.password} onChange={changeHandlerPassword} type="password" name="password" placeholder="Nouveau mot de passe" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12 col-md-12 col-12">
                                                                        <div className="form-field">
                                                                            <input onChange={changeHandlerPassword} value={password.password_confirmation} type="password" name="password_confirmation" placeholder="Confirmer le mot de passe" />
                                                                        </div>
                                                                        {erreurPassword.password && <span className="text-danger">{erreurPassword.password}</span>}
                                                                    </div>
                                                                </div>
                                                                <div className="form-submit my-2">
                                                                    <button className="btn theme-btn" type="submit">Changer</button>
                                                                    <button className="btn theme-btn bg-danger" onClick={toggleFormsPassword} >Annuler</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <button className='btn theme-btn mt-3' onClick={toggleFormsPassword}>
                                                    Changer de mot de passe
                                                </button>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-12">
                                <div className="category-item">

                                    {
                                        changeInfos ?
                                            (
                                                <div className='infos-pg-infos-section'>
                                                    <div className="infos-form-area">
                                                        <div className="section-title-s3 section-title-s5">
                                                            <h2>Modifications d'information</h2>
                                                        </div>
                                                        <div className="infos-form">
                                                            <form onSubmit={submitChangeInfo} className="form">
                                                                <div className="row">
                                                                    <div className="col-lg-6 col-md-6 col-12">
                                                                        <div className="form-field">
                                                                            <input value={infos.first_name} onChange={changeHandlerInfos} type="text" name="first_name" placeholder="Nom" />
                                                                            {erreurInfos.first_name && <span className="text-danger">{erreurPassword.first_name}</span>}

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-12">
                                                                        <div className="form-field">
                                                                            <input value={infos.last_name} onChange={changeHandlerInfos} type="text" name="last_name" placeholder="Prénoms" />
                                                                            {erreurInfos.last_name && <span className="text-danger">{erreurInfos.last_name}</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-12">
                                                                        <div className="form-field">
                                                                            <input onChange={changeHandlerInfos} value={infos.email} type="email" name="email" placeholder="E-mail" />
                                                                            {erreurInfos.email && <span className="text-danger">{erreurInfos.email}</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-12">
                                                                        <div className="form-field">
                                                                            <input onChange={changeHandlerInfos} value={infos.telephone} type="phone" name="telephone" placeholder="Téléphone" />
                                                                            {erreurInfos.telephone && <span className="text-danger">{erreurInfos.telephone}</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="form-field">
                                                                            <textarea name="adresse" onChange={changeHandlerInfos} value={infos.adresse} placeholder="Adresse"></textarea>
                                                                            {erreurInfos.adresse && <span className="text-danger">{erreurInfos.adresse}</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="form-submit">
                                                                            <button type="submit" className="theme-btn mx-3">Sauvegarder</button>
                                                                            <button className="theme-btn bg-danger mx-3" onClick={toggleFormsInfos} >Annuler</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className="section-title-s3 section-title-s5">
                                                        <h2>Mes Informations</h2>
                                                    </div>
                                                    <table className="table table-striped-columns" style={{ borderSpacing: '15px' }}>
                                                        <tbody className='text-align-left'>
                                                            <tr>
                                                                <td className='fw-bold' style={{ padding: '15px', textAlign: 'left' }}>Nom</td>
                                                                <td style={{ padding: '15px', textAlign: 'left' }}>{user.last_name}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='fw-bold' style={{ padding: '15px', textAlign: 'left' }}>Prénom</td>
                                                                <td style={{ padding: '15px', textAlign: 'left' }}>{user.first_name}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='fw-bold' style={{ padding: '15px', textAlign: 'left' }}>Email</td>
                                                                <td style={{ padding: '15px', textAlign: 'left' }}>{user.email}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='fw-bold' style={{ padding: '15px', textAlign: 'left' }}>Téléphone</td>
                                                                <td colSpan="2" style={{ padding: '15px', textAlign: 'left' }}>{formatPhoneNumber(user.phone)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='fw-bold' style={{ padding: '15px', textAlign: 'left' }}>Adresse</td>
                                                                <td colSpan="2" style={{ padding: '15px', textAlign: 'left' }}>{user.adresse}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <button className='btn theme-btn w-100' onClick={toggleFormsInfos}>
                                                        Modifier mes informations
                                                    </button>

                                                </>
                                            )
                                    }
                                </div>

                                <div className="category-item">

                                    <span className='fw-bold fs-5'>MES COMMANDES</span>
                                    <Divider className='my-3' />
                                    {
                                        order != null && verifiedOrder == false ? (
                                            <table className="table table-striped-columns" style={{ borderSpacing: '15px' }}>
                                                <thead className='text-align-left' style={{ backgroundColor: "#02a1e3", color: 'white' }}>
                                                    <th className='py-3'>Prix Total</th>
                                                    <th className='py-3'>Status</th>
                                                    <th className='py-3'>Date de reception</th>
                                                    <th className='py-3'>Actions</th>

                                                </thead>
                                                <tbody className='text-align-left'>
                                                    {order.map((item) => (
                                                        <tr>
                                                            {/* <td className='fw-bold' style={{ padding: '15px', textAlign: 'left' }}>Nom</td> */}
                                                            <td>
                                                                <span className='fw-bold'>
                                                                    {formatPrice(item.total_amount + item.frais_livraison)}
                                                                </span> <br></br>
                                                                <span>({item.order_items_count > 1 ? item.order_items_count + " Produits" : item.order_items_count + " Produit"})</span>
                                                            </td>
                                                            <td>{item.status == 'waiting' ? (
                                                                item.is_cancel == false ? (
                                                                    <>
                                                                        <>
                                                                            <span className="text-dark fw-bold">{item.methode_paiement == 'delivred' ? 'Payer a la livraison' : 'Payer Cash'}</span> <br />
                                                                            <span className='text-danger text-uppercase'>{item.status}</span>
                                                                        </>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span className="text-dark fw-bold">{item.methode_paiement == 'delivred' ? 'Payer a la livraison' : 'Payer Cash'}</span> <br />
                                                                        <span className='text-danger text-uppercase'>Annuler</span>
                                                                    </>
                                                                )

                                                            ) : (
                                                                <>
                                                                    <span className="text-dark fw-bold">{item.methode_paiement == 'delivred' ? 'Payer a la livraison' : 'Payer Cash'}</span> <br />
                                                                    <span className='text-uppercase'>{item.status}</span>
                                                                </>
                                                            )}</td>
                                                            <td>{item.other_receiver_id ? (
                                                                <>
                                                                    <span className="text-uppercase fw-bold">
                                                                        Autres
                                                                    </span> <br />
                                                                    <span className="text-dark">
                                                                        {item.created_at}
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span className="text-uppercase fw-bold">
                                                                        Moi Même
                                                                    </span> <br />
                                                                    <span className="text-dark">
                                                                        {item.created_at}
                                                                    </span>
                                                                </>
                                                            )}</td>
                                                            <td>
                                                                {item.status == 'waiting' ? (
                                                                    item.is_cancel == false ? (
                                                                        <>
                                                                            <button className='btn theme-btn bg-danger p-1 py-0 mx-1 rounded' onClick={() => confirmation(item.id)} >
                                                                                Annuler
                                                                            </button>
                                                                            <Link className='btn theme-btn p-0 pe-2 rounded mx-1' to={`/my-order/${item.id}`}>
                                                                                <i className='fa fa-eye '></i>
                                                                            </Link>
                                                                        </>
                                                                    ) : null
                                                                ) : (
                                                                    <Link className='btn theme-btn p-0 pe-2 rounded mx-1' to={`/my-order/${item.id}`}>
                                                                        <i className='fa fa-eye '></i>
                                                                    </Link>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))

                                                    }
                                                </tbody>
                                            </table>
                                        ) : (
                                            <>
                                                <Alert color="primary" className='mt-5'>
                                                    Vous n'aviez éffectuée aucune commande pour le moment
                                                </Alert>
                                            </>

                                        )

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
}

export default ProfileCard;
