import React, { Fragment, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Collapse from "@material-ui/core/Collapse";
import FontAwesome from "../../components/UiStyle/FontAwesome";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Link, useNavigate } from 'react-router-dom'
import { totalPrice } from "../../utils";

// images
import visa from '../../images/icon/visa.png';
import mastercard from '../../images/icon/mastercard.png';
import skrill from '../../images/icon/skrill.png';
import paypal from '../../images/icon/paypal.png';

import CheckWrap from '../CheckWrap'

import './style.scss';
import { clearCarlist } from '../../store/actions/action';
import { useDispatch } from 'react-redux';
import { checkStripePay, formatPrice, getDiscountedPrice, URL_API } from '../../utils/helpers';
import axios from 'axios';
import { toast } from 'react-toastify';

const cardType = [
	{
		title: 'visa',
		img: visa
	},
	{
		title: 'mastercard',
		img: mastercard
	},
	{
		title: 'skrill',
		img: skrill
	},
	{
		title: 'paypal',
		img: paypal
	},
];


const CheckoutSection = ({ cartList }) => {
	// states
	const [tabs, setExpanded] = React.useState({
		cupon: false,
		billing_adress: true,
		payment: false
	});

	const [dif_ship, setDif_ship] = React.useState(false);
	const [isProcessing, setIsProcessing] = React.useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [forms, setForms] = React.useState({
		user_id: '',
		fname: '',
		lname: '',
		address: '',
		email: '',
		phone: '',
		note: '',

		payment_method: 'cash',

		fname2: '',
		lname2: '',
		address2: '',
		email2: '',
		phone2: '',
		create_account: '',

		card_holder: '',
		card_number: '',
		cvv: '',
		expire_date: '',
	});

	const [validate, setValidate] = React.useState({
		user_id: '',
		fname: '',
		lname: '',
		address: '',
		email: '',
		phone: '',
		note: '',

		payment_method: 'cash',

		fname2: '',
		lname2: '',
		address2: '',
		email2: '',
		phone2: '',
		create_account: '',

		card_holder: '',
		card_number: '',
		cvv: '',
		expire_date: '',
	});


	// tabs handler
	function faqHandler(name) {
		setExpanded({
			cupon: false,
			billing_adress: false,
			payment: true, [name]: !tabs[name]
		});
	}

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			const userData = {
				fname: user.first_name,
				lname: user.last_name,
				address: user.adresse,
				email: user.email,
				phone: user.phone,
				user_id: user.id

			};
			setForms(prevForms => ({
				...prevForms,
				...userData
			}));
		}
	}, []);

	// forms handler
	const changeHandler = e => {
		setForms({ ...forms, [e.target.name]: e.target.value })
	};

	const submitCheckout = async (e) => {
		e.preventDefault()
		const token = localStorage.getItem('login');
		setIsProcessing(true);

		try {

			const response = await axios.post(`${URL_API}/ordered`, {
				...forms,
				cart: cartList
			}, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			if (response.data.status == 400) {
				setValidate(response.data.errors)
				toast.error('Une erreur c\'est prouduite, Veuillez verifez les donnees')
			}
			if (response.data.status == 500) {
				toast.error(response.data.message)
			}
			if (response.data.status == 'success') {
				toast.success(response.data.message)
				dispatch(clearCarlist());
				navigate(`/order_received/${response.data.order_id}`)
			}
		} catch (error) {
			// console.error(error.response.data);
			toast.info('Oups !! Une erreur c\'est produite')
		}
		setIsProcessing(false);
	}


	return (
		// cartList.length <= 0 ? (
		<Fragment>
			<Grid className="checkoutWrapper section-padding">
				<Grid className="container" container spacing={3}>
					<Grid item md={6} xs={12}>
						<div className="check-form-area">
							<form className="cuponForm" onSubmit={submitCheckout}>

								<Grid className="cuponWrap checkoutCard">
									<Button className="collapseBtn" fullWidth onClick={() => faqHandler('billing_adress')}>
										Addresse de facturation
										<FontAwesome name={tabs.billing_adress ? 'minus' : 'plus'} />
									</Button>
									<Collapse in={tabs.billing_adress} timeout="auto" unmountOnExit>
										<Grid className="chCardBody">
											<Grid container spacing={3}>
												<Grid item sm={6} xs={12}>
													<TextField
														fullWidth
														label="First Name"
														name="fname"
														value={forms.fname}
														onChange={(e) => changeHandler(e)}
														type="text"
														InputLabelProps={{
															shrink: true,
														}}
														className="formInput radiusNone"
													/>
													{validate.fname && <span className="text-danger">{validate.fname}</span>}
												</Grid>
												<Grid item sm={6} xs={12}>
													<TextField
														fullWidth
														label="Last Name"
														name="lname"
														value={forms.lname}
														onChange={(e) => changeHandler(e)}
														type="text"
														InputLabelProps={{
															shrink: true,
														}}
														className="formInput radiusNone"
													/>
													{validate.lname && <span className="text-danger">{validate.lname}</span>}
												</Grid>
												<Grid item xs={12}>
													<TextField
														fullWidth
														multiline
														rows="3"
														label="Address"
														name="address"
														value={forms.address}
														onChange={(e) => changeHandler(e)}
														type="text"
														InputLabelProps={{
															shrink: true,
														}}
														className="formInput radiusNone"
													/>
													{validate.address && <span className="text-danger">{validate.address}</span>}
												</Grid>
												<Grid item sm={6} xs={12}>
													<TextField
														fullWidth
														label="Email Adress"
														name="email"
														value={forms.email}
														onChange={(e) => changeHandler(e)}
														type="email"
														InputLabelProps={{
															shrink: true,
														}}
														className="formInput radiusNone"
													/>
													{validate.email && <span className="text-danger">{validate.email}</span>}
												</Grid>
												<Grid item sm={6} xs={12}>
													<TextField
														fullWidth
														label="Phone No"
														name="phone"
														value={forms.phone}
														onChange={(e) => changeHandler(e)}
														type="text"
														InputLabelProps={{
															shrink: true,
														}}
														className="formInput radiusNone"
													/>
													{validate.phone && <span className="text-danger">{validate.phone}</span>}
												</Grid>
												<Grid item xs={12}>
													<FormControlLabel
														className="checkBox"
														control={
															<Checkbox
																checked={dif_ship}
																onChange={() => setDif_ship(!dif_ship)}
																value={dif_ship}
																color="primary"
															/>
														}
														label="Ship to a different address?"
													/>
												</Grid>
												<Grid item xs={12}>
													<Collapse in={dif_ship} timeout="auto" unmountOnExit>
														<Grid container spacing={3}>
															<Grid item sm={6} xs={12}>
																<TextField
																	fullWidth
																	label="First Name"
																	name="fname2"
																	value={forms.fname2}
																	onChange={(e) => changeHandler(e)}
																	type="text"
																	InputLabelProps={{
																		shrink: true,
																	}}
																	className="formInput radiusNone"
																/>
																{validate.fname2 && <span className="text-danger">{validate.fname2}</span>}
															</Grid>
															<Grid item sm={6} xs={12}>
																<TextField
																	fullWidth
																	label="Last Name"
																	name="lname2"
																	value={forms.lname2}
																	onChange={(e) => changeHandler(e)}
																	type="text"
																	InputLabelProps={{
																		shrink: true,
																	}}
																	className="formInput radiusNone"
																/>

															</Grid>
															<Grid item xs={12}>
																<TextField
																	fullWidth
																	multiline
																	rows="3"
																	label="Address"
																	name="address2"
																	value={forms.address2}
																	onChange={(e) => changeHandler(e)}
																	type="text"
																	InputLabelProps={{
																		shrink: true,
																	}}
																	className="formInput radiusNone"
																/>
																{validate.address2 && <span className="text-danger">{validate.address2}</span>}
															</Grid>
															<Grid item sm={6} xs={12}>
																<TextField
																	fullWidth
																	label="Email Adress"
																	name="email2"
																	value={forms.email2}
																	onChange={(e) => changeHandler(e)}
																	type="email"
																	InputLabelProps={{
																		shrink: true,
																	}}
																	className="formInput radiusNone"
																/>
																{validate.email2 && <span className="text-danger">{validate.email2}</span>}
															</Grid>
															<Grid item sm={6} xs={12}>
																<TextField
																	fullWidth
																	label="Phone No"
																	name="phone2"
																	value={forms.phone2}
																	onChange={(e) => changeHandler(e)}
																	type="text"
																	InputLabelProps={{
																		shrink: true,
																	}}
																	className="formInput radiusNone"
																/>
																{validate.phone2 && <span className="text-danger">{validate.phone2}</span>}
															</Grid>
														</Grid>
													</Collapse>
												</Grid>
												<Grid item xs={12}>
													<TextField
														fullWidth
														multiline
														label="Order Notes"
														placeholder="Note about your order"
														name="note"
														value={forms.note}
														onChange={(e) => changeHandler(e)}
														type="text"
														InputLabelProps={{
															shrink: true,
														}}
														className="formInput radiusNone note"
													/>
													{validate.note && <span className="text-danger">{validate.note}</span>}
												</Grid>
											</Grid>
										</Grid>
									</Collapse>
								</Grid>
								<Grid className="cuponWrap checkoutCard">
									<Button className="collapseBtn" fullWidth onClick={() => faqHandler('payment')}>
										Methode de paiement
										<FontAwesome name={tabs.payment ? 'minus' : 'plus'} />
									</Button>
									<Grid className="chCardBody">
										<Collapse in={tabs.payment} timeout="auto">
											<RadioGroup className="paymentMethod" aria-label="Payment Method"
												name="payment_method"
												value={forms.payment_method}
												onChange={(e) => changeHandler(e)}>
												<FormControlLabel value="cash" control={<Radio color="primary" />}
													label="Payment By Card " />
												<FormControlLabel value="delivred" control={<Radio color="primary" />}
													label="Cash On delivery" />

											</RadioGroup>
											<Collapse in={forms.payment_method === 'cash'} timeout="auto">
												<Grid className="cardType">
													{cardType.map((item, i) => (
														<Grid
															key={i}
															className={`cardItem ${forms.card_type === item.title ? 'active' : null}`}
															onClick={() => setForms({ ...forms, card_type: item.title })}>
															<img src={item.img} alt={item.title} />
														</Grid>
													))}
												</Grid>
												<Grid>
													<Grid container spacing={3}>
														{/* <Grid item sm={6} xs={12}>
																<TextField
																		fullWidth
																		label="Card holder Name"
																		name="card_holder"
																		value={forms.card_holder}
																		onChange={(e) => changeHandler(e)}
																		type="text"
																		InputLabelProps={{
																				shrink: true,
																		}}
																		className="formInput radiusNone"
																/>
														</Grid> */}
														<Grid item sm={12} xs={12}>
															<TextField
																fullWidth
																label="Card Number"
																name="card_number"
																value={forms.card_number}
																onChange={(e) => changeHandler(e)}
																type="number"
																InputLabelProps={{
																	shrink: true,
																}}
																className="formInput radiusNone"
															/>
															{validate.card_number && <span className="text-danger">{validate.card_number}</span>}
														</Grid>
														<Grid item sm={6} xs={12}>
															<TextField
																fullWidth
																label="CVV"
																name="cvv"
																value={forms.cvv}
																onChange={(e) => changeHandler(e)}
																type="text"
																InputLabelProps={{
																	shrink: true,
																}}
																className="formInput radiusNone"
															/>
															{validate.cvv && <span className="text-danger">{validate.cvv}</span>}
														</Grid>
														<Grid item sm={6} xs={12}>
															<TextField
																fullWidth
																label="Expire Date"
																name="expire_date"
																value={forms.expire_date}
																onChange={(e) => changeHandler(e)}
																type='date'
																InputLabelProps={{
																	shrink: true,
																}}
																className="formInput radiusNone"
															/>
															{validate.expire_date && <span className="text-danger">{validate.expire_date}</span>}
														</Grid>

													</Grid>
													{/* <CheckWrap param={forms} shippedDifferent={dif_ship} /> */}
												</Grid>
											</Collapse>
											<Collapse in={forms.payment_method === 'delivred'} timeout="auto">

											</Collapse>

										</Collapse>

									</Grid>

								</Grid>
								<Grid item xs={12}>
									<Grid className="formFooter mt-20">
										{
											localStorage.getItem('login') ? (
												<Button fullWidth className="cBtn cBtnLarge cBtnTheme mt-20 ml-15" type="submit"> {isProcessing ? "Processing ... " : "Proceed to Checkout"}</Button>
											)
												:
												(
													<p className='text-uppercase fs-5 text-primary fw-bold'>
														Veuillez vous connecter ou créer un compte afin de pouvoir continuer le processus d'achat de vos articles.
													</p>
												)
										}
									</Grid>
								</Grid>
							</form>
						</div>
					</Grid>
					<Grid item md={6} xs={12}>
						<Grid className="cartStatus">
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Grid className="cartTotals">
										<h4>Total du panier</h4>
										<Table>
											<TableBody>
												{cartList.map(item => (
													<TableRow key={item.id}>
														<TableCell>{item.title} {getDiscountedPrice(item) !== null ? formatPrice(item.price) : item.delPrice ? formatPrice(item.delPrice) : formatPrice(item.price)} x {item.qty}</TableCell>
														<TableCell
															align="right">{getDiscountedPrice(item) !== null ? formatPrice(item.price * item.qty) : item.delPrice ? formatPrice(item.delPrice * item.qty) : formatPrice(item.price * item.qty)}</TableCell>
													</TableRow>
												))}
												<TableRow className="totalProduct">
													<TableCell>Total product</TableCell>
													<TableCell align="right">{cartList.length}</TableCell>
												</TableRow>
												<TableRow>
													<TableCell className='text-primary fw-bold'>Sous-prix</TableCell>
													<TableCell align="right" className='text-primary fw-bold'>{formatPrice(totalPrice(cartList))}</TableCell>
												</TableRow>
												<TableRow>
													<TableCell className='text-success fw-bold'>Frais de livraison</TableCell>
													<TableCell align="right" className='text-success fw-bold'>{formatPrice(totalPrice(cartList) * 10 / 100)}</TableCell>
												</TableRow>
												<TableRow>
													<TableCell className='fw-bold'>Prix ​​total</TableCell>
													<TableCell align="right" className='fw-bold'>{formatPrice((totalPrice(cartList) * 10 / 100) + totalPrice(cartList))}</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Fragment>
		// ) : (

		//     <Fragment>
		//         <Grid className="checkoutWrapper section-padding">
		//             <Grid className="container" container>
		//                 <Grid item md={6} xs={6}>
		//                     <Grid className="cartStatus">
		//                         <Grid container spacing={3}>
		//                             <Grid item xs={12}>
		//                                 <Grid className="cartTotals">
		//                                     <h4>Panier Vide</h4>
		//                                     <p className='text-uppercase fs-5 text-primary fw-bold'>

		//                                     </p>
		//                                 </Grid>
		//                             </Grid>
		//                         </Grid>
		//                     </Grid>
		//                 </Grid>
		//             </Grid>
		//         </Grid>
		//     </Fragment>
		// )

	)
};


export default CheckoutSection;