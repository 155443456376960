import React, { Fragment, useEffect, useRef, useState } from 'react';
import Navbar from '../Navbar'
import PageTitle from '../pagetitle'
import Footer from '../footer'
import Scrollbar from '../scrollbar'
import './myorder.css'
import { Divider } from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { formatPhoneNumber, formatPrice, PrintButtons, URL_API } from '../../utils/helpers';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';


const MyOrder = () => {

    const { id } = useParams();
    const token = localStorage.getItem('login');
    const [order, setOrder] = useState({});
    const [orderItems, setOrderItems] = useState([]);

    const componentRef = useRef(null);

    const showOrder = async (id) => {
        try {

            const parsedUser = JSON.parse(localStorage.getItem('user'));
            const response = await axios.get(`${URL_API}/show-my-order/${id}/${parsedUser.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.data.status === 200) {

                setOrder(response.data.order);
                setOrderItems(response.data.order_items);

            } else {
                toast.info('Une erreur est survenue lors de la récupération des données de la commande');
            }
        } catch (error) {
            toast.info('Erreur lors de la récupération des données de la commande');
        }

    }

    useEffect(() => {
        showOrder(id)
    }, [])

    return (
        <Fragment>
            <Navbar hClass={"header-style-2"} />
            <PageTitle pageTitle={'Ma Commande'} pagesub={'Mon Compte'} />
            <section className={`category-area-s2 section-padding style-2`}>
                <div className="container" >
                    <div className="category-wrap">
                        <div className="category-item p-4" ref={componentRef}>
                            <div className="category-content">
                                <div className="">
                                    <div className="row invoice-header">
                                        <div className="col-6 text-start">
                                            <h1 className="invoice-title"> B.C.A </h1>
                                            <p>1982 Harvest Lane New York, NY 12210<br />United States</p>
                                        </div>
                                        <div className="col-6 text-end">
                                            <p>Email : Edmin@themesforest.com<br />Website : www.EdminThemes.com<br />Contact No : (316) 555-0116</p>
                                        </div>
                                    </div>

                                    <div className="row py-3 border-bottom">
                                        <div className="col-4 text-start">
                                            <p className=' fw-bold'>Invoice No. <a href="#">#BCA-000{order.id}</a></p>
                                        </div>
                                        <div className='col-4'>
                                            <p className=' fw-bold'>Date : {order.created_at}</p>
                                        </div>
                                        <div className="col-4 text-end">
                                            <p className=' fw-bold'>Payment Status :
                                                {order.status === 'paied' || order.status === "Paied" ? (
                                                    <span style={{ margin: "0px 5px", fontWeight: 400, fontSize: 16, padding: "1px 5px", backgroundColor: "rgba(84, 186, 74, 0.1)", color: "#54BA4A", borderRadius: 5 }} >
                                                        Payée
                                                    </span>
                                                ) : (
                                                    <span style={{ margin: "0px 5px", fontWeight: 400, fontSize: 16, padding: "1px 5px", backgroundColor: "rgba(255, 0, 0, 0.1)", color: "#FF0000", borderRadius: 5 }} >
                                                        En Attente
                                                    </span>
                                                )}</p>
                                        </div>
                                    </div>

                                    <div className="row py-4 text-start">
                                        <div className="col-6">
                                            <h3 className='total'>ADRESSE DE FACTURATION</h3>
                                            <p>
                                                <strong>{order.user_firstname + " " + order.user_lastname}</strong>
                                                <br />{order.user_adresse}<br />Téléphone : {order.user_phone}
                                            </p>
                                        </div>
                                        <div className="col-6 text-end">
                                            <h3 className='total'>ADRESSE DE LIVRAISON</h3>
                                            <p>
                                                <strong>{order.other_receiver_id == null ? order.user_firstname + " " + order.user_lastname : order.fname2 + " " + order.lname2}</strong><br />
                                                {order.address2 == null ? order.user_adresse : order.address2}<br />
                                                Téléphone : {order.phone2 == null ? order.user_phone : formatPhoneNumber(order.phone2)}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Product Table */}
                                    <div className="row py-4">
                                        <div className="col-md-12">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className='text-start ps-4'>Produits</th>
                                                        <th>Prix</th>
                                                        <th>Quantité</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orderItems.map((data, i) => (
                                                        <tr className="invoice-dark" style={{ backgroundColor: "rgba(2, 161, 227, 0.1)", boxShadow: "0px 1px 0px 0px rgba(82, 82, 108, 0.15)" }} key={i}>
                                                            <td style={{ padding: "18px 15px", display: "flex", alignItems: "center", gap: 10 }} >
                                                                <span style={{ width: 54, height: 51, backgroundColor: "#F5F6F9", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 5 }} >
                                                                    <img src={data.product_img} alt="laptop" style={{ height: 29 }} />
                                                                </span>
                                                                <ul className="simple-list" style={{ padding: 0, margin: 0, listStyle: "none" }}>
                                                                    <li className="p-0">
                                                                        <h4 style={{ fontWeight: 400, margin: "4px 0px", fontSize: 16 }} >
                                                                            {data.product_name}
                                                                        </h4>
                                                                        {/* <span style={{ opacity: "0.8", fontSize: 14 }}>{data.code}</span> */}
                                                                    </li>
                                                                </ul>
                                                            </td>

                                                            <td style={{ padding: "18px 15px", width: "12%", textAlign: "center" }}>
                                                                <span style={{ fontSize: 14 }}>{formatPrice(data.product_price)}</span>
                                                            </td>
                                                            <td style={{ padding: "18px 15px", textAlign: "center" }}>
                                                                <span style={{ fontSize: 14 }}>{data.quantity}</span>
                                                            </td>
                                                            <td style={{ padding: "18px 15px", textAlign: "center" }}>
                                                                <span style={{ fontSize: 14 }}>{formatPrice(data.product_price * data.quantity)}</span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <table className="table text-end">
                                        <tr>
                                            <td>Subtotal :</td>
                                            <td className="text-end">{formatPrice(order.total_amount)}</td>
                                        </tr>
                                        <tr>
                                            <td>Frais de livraison :</td>
                                            <td className="text-end">{formatPrice(order.frais_livraison)}</td>
                                        </tr>
                                        <tr className="total">
                                            <td>Montant total :</td>
                                            <td className="text-end">{formatPrice(order.frais_livraison + order.total_amount)}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='text-center'>
                            <ReactToPrint
                                trigger={() => <button style={{
                                    background: "rgba(2, 161, 227, 1)",
                                    color: "rgba(255, 255, 255, 1)",
                                    borderRadius: 10,
                                    padding: "18px 27px",
                                    fontSize: 16,
                                    fontWeight: 600,
                                    outline: 0,
                                    border: 0,
                                    textDecoration: "none",
                                    cursor: "pointer"
                                }}>Télécharger ou Imprimer</button>}
                                content={() => componentRef.current}
                            />
                            <Link style={{ background: "rgba(2, 161, 227, 0.1)", color: "rgba(2, 161, 227, 1)", borderRadius: 10, padding: "18px 27px", margin: "0px 27px", fontSize: 16, fontWeight: 600, outline: 0, border: 0, textDecoration: "none", }} to={`/my-account`} download>
                                <i className="fa fa-reply-all" style={{ fontSize: 13, fontWeight: "bold", marginRight: 10 }} />
                                Quitter
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
}
export default MyOrder;