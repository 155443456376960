import React, { Fragment, useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import { useParams } from 'react-router-dom';
import ProductSingleSec from '../../components/ProductSingleSec';
import PageTitle from '../../components/pagetitle';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import { connect } from "react-redux";
import api from "../../api";
import { addToCart } from "../../store/actions/action";
import usePreviousPage from '../../utils/helpers';

const ProductDetailsPage = (props) => {
    const { id } = useParams();
    const productsArray = api();
    const Allproduct = productsArray;

    const { addToCart } = props;
    const [product, setProduct] = useState(null);

    useEffect(() => {
        const foundProduct = Allproduct.find(product => product.id === Number(id));
        setProduct(foundProduct);
    }, [Allproduct, id]);

    usePreviousPage();

    return (
        <Fragment>
            <Navbar hClass={'header-style-2'} />
            <PageTitle pageTitle={'Product Single'} pagesub={'Product'} />
            {product ? (
                <ProductSingleSec
                    item={product}
                    addToCart={addToCart}
                />
            ) : (
                <div>Product not found</div>
            )}
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

const mapStateToProps = state => {
    return {
        products: state.data.products,
    }
};

export default connect(mapStateToProps, { addToCart })(ProductDetailsPage);





// import React, { Fragment, useEffect, useState } from 'react';
// import Navbar from '../../components/Navbar'
// import { useParams } from 'react-router-dom'
// import ProductSingleSec from '../../components/ProductSingleSec'
// import PageTitle from '../../components/pagetitle'
// import Footer from '../../components/footer'
// import Scrollbar from '../../components/scrollbar'
// import { connect } from "react-redux";
// import api from "../../api";
// import { addToCart } from "../../store/actions/action";


// const ProductDetailsPage = (props) => {

//     const { id } = useParams()

//     const productsArray = api();
//     const Allproduct = productsArray

//     const { addToCart } = props;
//     const [product, setProduct] = useState({});

//     useEffect(() => {
//         setProduct(Allproduct.filter(Allproduct => Allproduct.id === Number(id)))
//     }, []);
//     const item = product[0];

//     return (
//         <Fragment>
//             <Navbar hClass={'header-style-2'} />
//             <PageTitle pageTitle={'Product Single'} pagesub={'Product'} />
//             {item ? <ProductSingleSec
//                 item={item}
//                 addToCart={addToCart}
//             /> : null}
//             <Footer />
//             <Scrollbar />
//         </Fragment>
//     )
// };

// const mapStateToProps = state => {
//     return {
//         products: state.data.products,
//     }
// };

// export default connect(mapStateToProps, { addToCart })(ProductDetailsPage);
