import React, { useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";

import author1 from "../../../images/product-details/author.png";
import author2 from "../../../images/product-details/author2.png";
import { toast } from 'react-toastify';
import axios from 'axios';
import { URL_API } from '../../../utils/helpers';
import { Alert } from 'reactstrap';

const Reviews = ({ id }) => {

    const [reviews, setReviews] = React.useState([]);
    const getReviews = async () => {
        try {

            const response = await axios.get(`${URL_API}/reviews-product/${id}`);

            if (response.data.status == 200) {
                setReviews(response.data.reviews);
            }

        } catch (error) {
            toast.info("Oups ! Une erreur c'est produite")
        }
    }

    useEffect(() => {
        getReviews()
    }, []);

    return (
        <Grid className="prdTabContent faqReview">
            {
                reviews == null ? (
                    <Alert color='info' className='text-center'>
                        <h4>
                            Devenez le premier a commenter cet article. . .
                        </h4>
                    </Alert>
                ) : (
                    reviews.map((item) => {
                        return (
                            <Grid>
                                <h2>Les Commentaires</h2>
                                <Grid className="reviewItems" key={item.id}>
                                    <Grid className="reviewImg">
                                        <img src={author1} alt="author1" />
                                    </Grid>
                                    <Grid className="reviewContent">
                                        <h2><Link to="/">{item.name}</Link></h2>
                                        <p>{item.comment}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })
                )

            }
        </Grid>
    )
};

export default Reviews;