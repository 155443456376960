import React, { Fragment, useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import usePreviousPage, { formatDate, Pagination, URL_API } from '../../utils/helpers';
import { toast } from 'react-toastify';
import axios from 'axios';

const Ressources = () => {
    usePreviousPage();

    const [menus, setMenus] = useState([]);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const getData = () => {
        axios.get(`${URL_API}/getRessources`)
            .then(response => {
                setMenus(response.data.menus || []);
            })
            .catch(error => {
                toast.error("Oups !! Erreur de récupération des données");
            });
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <Fragment>
            <Navbar hClass={"header-style-2"} />
            <PageTitle pageTitle={'Ressources'} pagesub={'Ressources'} />

            <section className="wpo-blog-pg-section section-padding pt-5">
                <div className="container w-50" style={{ textAlign: 'justify' }}>
                    <div className='pb-5' dangerouslySetInnerHTML={{ __html: menus.description || '' }}>
                    </div>
                    {/* <div className="row">
                        <div className={`col col-lg-8 col-12 order-lg-2`}>
                            <div className="wpo-blog-content">
                                {currentItem && (  // Vérifier si currentItem n'est pas null avant de rendre
                                    <div className={`post format-video`}>
                                        <div className="entry-media video-holder">
                                            <img src={currentItem.img} alt={currentItem.title} />
                                            <VideoModal />
                                        </div>
                                        <div className="entry-details mt-4">
                                            <h3>
                                                <Link onClick={ClickHandler} to={"#"}>{currentItem.title}</Link>
                                            </h3>
                                            <p dangerouslySetInnerHTML={{ __html: currentItem.description }} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={`col col-lg-4 col-12 order-lg-2`}>
                            <div className="blog-sidebar">
                                <div className="widget recent-post-widget">
                                    <h3>Nos Actualités</h3>
                                    <div className="posts">
                                        {currentMenusItems.map((item, Bitem) => (
                                            <div className="post" key={Bitem}>
                                                <div className="img-holder">
                                                    <img src={item.img} alt={item.title} />
                                                </div>
                                                <div className="details">
                                                    <h4><Link onClick={getItems(item.id)} to={`#`}>{item.title}</Link></h4>
                                                    <h4><Link onClick={() => getItems(item.id)} to={`#`}>{item.title}</Link></h4>
                                                    <span className="date">{formatDate(item.created_at)}</span>
                                                </div>
                                            </div>
                                        ))}
                                        {totalPages > 1 ? <Pagination totalPages={totalPages} paginate={paginate} currentPage={currentPage} /> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>

            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default Ressources;
