import React from 'react';
import ContactForm from '../ContactFrom'

const Contactpage = () => {

    return (
        <section className="contact-pg-contact-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 col-12">
                        <div className="section-title-s3 section-title-s5">
                            <h2>Nos contacts</h2>
                        </div>
                        <div className="contact-details">
                            <p>Chez Best Centrale d'Achat SARL, nous valorisons la communication ouverte et l'accessibilité. Nous mettons à votre disposition plusieurs moyens pour entrer en contact avec nous, que ce soit pour des demandes d'information, des devis ou des consultations personnalisées.</p>
                            <ul>
                                <li>
                                    <div className="icon">
                                        <i className="ti-location-pin"></i>
                                    </div>
                                    <h5>Notre emplacement</h5>
                                    <p>Conakry, Matoto, Gbessia Kondebougny T-1</p>
                                </li>
                                <li>
                                    <div className="icon">
                                        <i className="ti-mobile"></i>
                                    </div>
                                    <h5>Téléphone</h5>
                                    <p>+224 620 451 485</p>
                                </li>
                                <li>
                                    <div className="icon">
                                        <i className="ti-email"></i>
                                    </div>
                                    <h5>E-mail</h5>
                                    <p>contact@bestcentrale.com</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                        <div className="contact-form-area">
                            <div className="section-title-s3 section-title-s5">
                                <h2>Formulaire de contact rapide</h2>
                            </div>
                            <div className="contact-form">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="contact-map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d793.1462648066318!2d-13.63638434931553!3d9.568861093763374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sfr!2s!4v1727186271419!5m2!1sfr!2s"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Contactpage;
