import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { URL_API } from "../../utils/helpers";

const prices = [
  {
    id: 1,
    min: 1000,
    max: 10000,
  },
  {
    id: 2,
    min: 10001,
    max: 100000,
  },
  {
    id: 3,
    min: 100001,
    max: null,
  },
];

const FilterSidebar = ({ filter, changeHandler, priceChangeHandler }) => {

  const [brands, setBrands] = useState([]);

  const cateFilter = async () => {
    try {
      const token = localStorage.getItem('login');

      const response = await axios.get(`${URL_API}/admin/categorie-filter`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data.status === 200) {
        // brands = response.data.cate;
        setBrands(response.data.cate);
      } else {
        toast.error('Something went wrong');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to fetch categories');
    }
  };

  useEffect(() => {
    cateFilter();
  }, []);

  return (
    <div className="col-lg-4">
      <div className="shop-filter-wrap">
        <div className="filter-item">
          <div className="shop-filter-item">
            <h2>Rechercher</h2>
            <div className="shop-filter-search">
              <form>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Recherche"
                    name="name"
                    value={filter.name}
                    onChange={changeHandler}
                  />
                  <button type="submit">
                    <i className="ti-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="filter-item">
          <div className="shop-filter-item">
            <h2>Prix FG</h2>
            <ul>
              <li>
                <label className="topcoat-radio-button__label">
                  Tous Les Prix
                  <input
                    type="radio"
                    value={""}
                    checked={!filter.price}
                    name="price"
                    onChange={changeHandler}
                  />
                  <span className="topcoat-radio-button"></span>
                </label>
              </li>
              {prices.map((price, index) => (
                <li key={index}>
                  <label className="topcoat-radio-button__label">
                    {price.min} {price.max ? "- " : ""}
                    {price.max || " et plus"}
                    <input
                      checked={filter.price.id === price.id}
                      type="radio"
                      value={JSON.stringify(price)}
                      onChange={priceChangeHandler}
                      name="price"
                    />
                    <span className="topcoat-radio-button"></span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="filter-item">
          <div className="shop-filter-item">
            <h2>Catégories</h2>
            <ul>
              <li>
                <label className="topcoat-radio-button__label">
                  Toutes les catégories
                  <input
                    checked={filter.brand === ""}
                    type="radio"
                    value=""
                    onChange={changeHandler}
                    name="brand"
                  />
                  <span className="topcoat-radio-button"></span>
                </label>
              </li>
              {brands.map((brand, index) => (
                <li key={index}>
                  <label className="topcoat-radio-button__label">
                    {brand}
                    <input
                      checked={filter.brand === brand}
                      type="radio"
                      value={brand}
                      onChange={changeHandler}
                      name="brand"
                    />
                    <span className="topcoat-radio-button"></span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterSidebar;
