import React, { Fragment, useState } from "react";
import { Dialog, Grid, Button, colors } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import bee from '../../images/bee2.png'
import { Link } from "react-router-dom";
import { formatPrice, getDiscountedPrice, truncateDescription } from "../../utils/helpers";

const DefaultModal = ({
  maxWidth,
  open,
  onClose,
  addToCartProduct,
  product,
}) => {
  const [qty, setQty] = useState(1);

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <i className="fa fa-close"></i>
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const sizeArray = product.sizes ? product.sizes.split(',') : [];
  const colorArray = product.colors ? product.colors.split(',') : [];
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedSize, setSelectedSize] = useState('');

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  const handleSizeSelect = (size) => {
    setSelectedSize(size);
  };

  const handleAddToCart = () => {
    addToCartProduct(product, qty, selectedColor, selectedSize)
    setQty(1);
    setSelectedColor('');
    setSelectedSize('');
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        className="modalWrapper quickview-dialog"
        maxWidth={maxWidth}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={onClose}
        ></DialogTitle>
        <Grid className="modalBody modal-body">
          <div className="product-details">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <div className="product-single-img">
                  <div className="modal-product">
                    <div className="item">
                      <img src={product && product.proImg} alt="proImg" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="product-single-content">
                  <h5>{product && product.title} <span style={{ fontSize: '15px', color: "#028bc5" }}>{product.qualite}</span></h5>
                  <div className="product-price-modal">
                    <ul>
                      <li>{formatPrice(product.price)}</li>
                      <li>
                        {
                          getDiscountedPrice(product) ?? product.delPrice
                        }
                      </li>

                    </ul>
                  </div>
                  <ul className="rating">
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                  </ul>
                  <p>
                    {product.description}
                  </p>
                  <div className="product-filter-item color">
                    <div className="color-name">
                      <span>Color :{selectedColor} </span>
                      <ul>
                        <ul>
                          {colorArray.map((color, index) => (
                            <li key={index} className="color">
                              <input
                                id={`color-${index}`}
                                type="radio"
                                name="color"
                                value={color}
                                onChange={() => setSelectedColor(color)}
                              />
                              <label htmlFor={`color-${index}`} style={{ backgroundColor: color }}>
                              </label>
                            </li>
                          ))}
                        </ul>
                      </ul>
                    </div>
                  </div>
                  <div className="product-filter-item color filter-size">
                    <div className="color-name">
                      <span>Weight : {selectedSize}</span>
                      <ul>
                        <ul>
                          {sizeArray.map((size, index) => (
                            <li key={index} className="color">
                              <input
                                id={`size-${index}`}
                                type="radio"
                                name="size"
                                value={size}
                                onChange={() => setSelectedSize(size)}
                              />
                              <label htmlFor={`size-${index}`}>{size}</label>
                            </li>
                          ))}
                        </ul>
                      </ul>
                    </div>
                  </div>
                  <div className="pro-single-btn">
                    <Grid className="quantity cart-plus-minus">
                      <Button
                        className="dec qtybutton"
                        onClick={() => setQty(qty <= 1 ? 1 : qty - 1)}
                      >
                        -
                      </Button>
                      <input
                        value={qty}
                        onChange={() => setQty(qty)}
                        type="text"
                      />
                      <Button
                        className="inc qtybutton"
                        onClick={() => setQty(qty + 1)}
                      >
                        +
                      </Button>
                    </Grid>
                    <button
                      onClick={() => handleAddToCart()}
                      className="theme-btn"
                    >
                      Add to cart
                    </button>
                  </div>
                  <div className="social-share">
                    <span>Share with : </span>
                    <ul className="socialLinks">
                      <li>
                        <Link to="/">
                          <i className="fa fa-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <i className="fa fa-linkedin"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <i className="fa fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <i className="fa fa-instagram"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <i className="fa fa-youtube-play"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="m-shape">
                    <img src={bee} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Dialog>
    </Fragment>
  );
};
export default DefaultModal;
